import React from "react";
import "./Form.css";
import axios from "axios";
import { useHistory } from "react-router-dom";

function Form(props) {
  const [phone, setPhone] = React.useState("");
  const [name, setName] = React.useState("");
  const [isKey, setKey] = React.useState("");
  const [spanName, setSpanName] = React.useState(false);
  const [spanTel, setSpanTel] = React.useState(false);
  const [isDisabled, setDisabled] = React.useState(true);

  const history = useHistory();

  const regex = /['a-z','-','/','.',':','-','*']/g;
  const regex3 = /['0-9']/g;
  const regex2 = /(http|https):\/\/([\w/.!&#@-]*).([a-z]{2,})([\w/.!&#@-]*)/gi;
  const regex5 = /^((8|\+374|\+994|\+995|\+375|\+7|\+380|\+38|\+996|\+998|\+993)[- ]?)?\(?\d{3,5}\)?[- ]?\d{1}[- ]?\d{1}[- ]?\d{1}[- ]?\d{1}[- ]?\d{1}(([- ]?\d{1})?[- ]?\d{1})?$/;

  function handleChange(e) {
    if (
      regex.test(e.target.value) ||
      regex2.test(e.target.value) ||
      regex3.test(e.target.value)
    ) {
      e.target.value = "";
      alert("Введены запрещенные символы. Используйте только русские буквы.");
    }
  }

  function change(e) {
    // e.target.value.length <= 1 ? setPhone('8' + e.target.value) : setPhone(`${e.target.value.replace(/\s+/g, '').substring(0, 1)} ${e.target.value.replace(/\s+/g, '').substring(1, 4)} ${e.target.value.replace(/\s+/g, '').substring(4, 7)} ${e.target.value.replace(/\s+/g, '').substring(7, e.target.value.replace(/\s+/g, '').length)}`)
    // isKey === 8 && e.target.value.length <= 2 ? setPhone('+7') : isKey === 8 && e.target.value.length >= 2 ? setPhone(e.target.value) : e.target.value.length <= 2 || regex.test(e.target.value) || regex4.test(e.target.value) ? setPhone('+7') : regex.test(e.target.value) || regex4.test(e.target.value) ? setPhone('+7') : setPhone(`${e.target.value.replace(/\s+/g, '').substring(0, 2)} ${e.target.value.replace(/\s+/g, '').substring(2, 5)} ${e.target.value.replace(/\s+/g, '').substring(5, 8)} ${e.target.value.replace(/\s+/g, '').substring(8, 10)} ${e.target.value.replace(/\s+/g, '').substring(10, e.target.value.replace(/\s+/g, '').length)}`);
    isKey === 8 && e.target.value.length <= 2
      ? setPhone("+7")
      : isKey === 8 && e.target.value.length >= 2
      ? setPhone(e.target.value.replace(/\s+/g, ""))
      : e.target.value.length >= 2
      ? setPhone(
          `${e.target.value
            .replace(/\s+/g, "")
            .substring(0, 2)} ${e.target.value
            .replace(/\s+/g, "")
            .substring(2, 5)} ${e.target.value
            .replace(/\s+/g, "")
            .substring(5, 8)} ${e.target.value
            .replace(/\s+/g, "")
            .substring(8, 10)} ${e.target.value
            .replace(/\s+/g, "")
            .substring(10, e.target.value.replace(/\s+/g, "").length)}`
        )
      : setPhone("+7");
  }
  function onSubmit(e) {
    e.preventDefault();
    let formData = new FormData();
    if (props.question) {
      formData.append("question", props.question);
    }
    formData.append("name", name);
    formData.append("tel", phone);
    axios({
      method: "POST",
      url: "./send.php",
      headers: {
        "Access-Control-Allow-Headers": "content-type",
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: formData,
    })
      .then(function (response) {
        if (response.data.result === "success") {
          props.setThanks(true);
          history.push("/thank-you");
          props.onClose();
        }
      })
      .catch(function (response) {
        console.log(response);
      });
  }
  return (
    <form className="form" id="form">
      <div className="input__label">
        <span
          className={
            spanName ? "input__span input__span_active" : "input__span"
          }
        >
          Имя
        </span>
        <input
          className="form__input"
          type="text"
          name="name"
          placeholder="Имя"
          minLength="2"
          maxLength="30"
          value={name}
          onFocus={() => setSpanName(true)}
          onBlur={(e) =>
            e.target.value === "" ? setSpanName(false) : setSpanName(true)
          }
          onChange={(e) => {
            handleChange(e);
            setName(e.target.value);
            e.target.value.length !== "" && phone.length === 16
              ? setDisabled(false)
              : setDisabled(true);
          }}
          autoComplete="off"
          required={true}
        />
      </div>
      <div className="input__label">
        <span
          className={spanTel ? "input__span input__span_active" : "input__span"}
        >
          Телефон
        </span>
        <input
          className="form__input"
          type="tel"
          name="tel"
          onFocus={() => setSpanTel(true)}
          onBlur={(e) =>
            e.target.value === "" ? setSpanTel(false) : setSpanTel(true)
          }
          placeholder="Телефон"
          value={phone}
          onClick={(e) =>
            e.target.value.replace(/\s+/g, "").length !== 0 ? (
              <></>
            ) : (
              setPhone("+7")
            )
          }
          minLength="16"
          maxLength="16"
          onKeyDown={(e) => {
            setKey(e.keyCode);
          }}
          onChange={(e) => {
            change(e);
            name !== "" &&
            e.target.value.replace(/\s+/g, "").length > 11 &&
            regex5.test(e.target.value)
              ? setDisabled(false)
              : setDisabled(true);
          }}
          autoComplete="off"
          required={true}
        />
      </div>
      {props.children}
      <button
        className={
          isDisabled
            ? "form__button form__button_disabled"
            : "form__button light"
        }
        onClick={(e) => onSubmit(e)}
        type="submit"
        value="Отправить"
        disabled={isDisabled}
      >
        {props.button}
      </button>
      {props.name === "date" ? (
        <p className="form__smile">Будем рады видеть Вас в Хеппо!&#128522;</p>
      ) : props.name === "booking" ? (
        <p className="form__smile">
          Мы свяжемся с Вами для уточнения деталей бронирования.
        </p>
      ) : (
        <></>
      )}
    </form>
  );
}

export default Form;
